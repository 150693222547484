import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo, useState } from 'react';
import useStepper from '#/hooks/useStepper';
import useLocales from '#/hooks/useLocales';
import { PowerOfAttorneyFormValues } from '#/types/powerOfAttorney';
import { FormProvider } from '#/components/shared/hook-form';
import { QontoConnector } from '../LivingWill/Steps/styles';
import { stepsData } from './steps/content/stepsData';
import QontoStepIcon from '../LivingWill/Steps/QontoStepIcon';
import EnoughForNowModal from '#/components/shared/ui/EnoughForNowModal';
import {
  powerOfAttorneySchema,
  schemaValues,
} from '#/components/pages/PowerAttorney/schemaValidation';
import {
  invalidateServicesStatsQueries,
  useGetServiceForm,
  useStartService,
} from '#/api/servicesQueries';
import { useUpdateRepresentative } from '#/api/representativeQueries';
import RequiredBasicInfo from '#/components/pages/User/RequiredBasicInfo';
import useAuth from '#/hooks/useAuth';
import useServiceStats from '#/hooks/useServiceStats';
import MarkAsDone from '#/components/shared/mark-as-done';

const PowerAttorneyForm = () => {
  const { user } = useAuth();
  const { state } = useLocation();
  const { last_will, power_of_attorney } = useServiceStats();

  const { mutateAsync: initialisePowerOfAttorney } =
    useStartService('powerofattorney');

  const [openEnoughNow, setOpenEnoughNow] = useState(false);
  const [nextSubmitted, setNextSubmitted] = useState<number | null>(null);

  const { data: dbFormValues } = useGetServiceForm('power-of-attorney');

  const handleOpenEnoughNow = () => setOpenEnoughNow(true);

  const handleCloseEnoughNow = () => setOpenEnoughNow(false);

  const { handleNext, handleBack, activeStep, setActiveStep } = useStepper(
    false,
    'power_of_attorney',
    stepsData
  );
  const { translate } = useLocales();

  const navigate = useNavigate();

  const defaultValues = useMemo(
    () => schemaValues(dbFormValues, activeStep),
    [dbFormValues, activeStep]
  );

  const methods = useForm<PowerOfAttorneyFormValues>({
    resolver:
      nextSubmitted === activeStep
        ? (yupResolver(powerOfAttorneySchema) as any)
        : undefined,
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
    watch,
  } = methods;

  const { mutateAsync: addRepresentativeRelation } = useUpdateRepresentative(
    Number(watch('trusted_person_for_children'))
  );

  const submitForm = async ({ data = watch() }: { data: any }) => {
    const reqData = {
      ...data,
      ...Object.fromEntries(
        Object.entries(data).map(([key, value]) => [
          key,
          value === '' ? null : value,
        ])
      ),
    };

    if (Number(reqData.trusted_person_for_children) !== 0 || null) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      await addRepresentativeRelation({
        relationship: reqData.trusted_person_role_for_children,
      });
    }
    await initialisePowerOfAttorney(reqData).then(() => {
      handleNext();
      invalidateServicesStatsQueries.getServicesStats();
      invalidateServicesStatsQueries.getServiceForm('power-of-attorney');
    });
  };

  const onSubmit = async (data: any) => {
    await submitForm({ data });
  };

  const translatedStepsData = stepsData.map((step, i) => ({
    ...step,
    label: translate(`powerOfAttorney.stepperInfo.${i}.label`),
    title: translate(`powerOfAttorney.stepperInfo.${i}.title`),
  }));

  const shortPainFree = activeStep === 0;
  const madeIt = activeStep === 1;

  useEffect(() => {
    if (dbFormValues) {
      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [dbFormValues]);

  useEffect(
    () => () => {
      const submit = async () => {
        await submitForm({ data: watch() });
      };

      submit().then((r) => r);
    },
    // eslint-disable-next-line
    []
  );

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const basicInfoMissing =
    user?.civil_status === null ||
    user?.civil_status === undefined ||
    user?.civil_status === '';

  const hasNoLastWill = !last_will?.service?.completed;

  if (
    state?.step === 'markAsDone' ||
    state?.step === 'history' ||
    state?.step === 'uploadDocument'
  ) {
    return (
      <MarkAsDone
        serviceName="powerofattorney"
        serviceInvalidateKey="power-of-attorney"
        documentType="powerOfAttorney"
      />
    );
  }

  return (
    <Container maxWidth="lg">
      {shortPainFree && (
        <Typography variant="h2" mb={5} textAlign="center">
          {String(translate('powerOfAttorney.mainHeaders.shortPainFree'))}
        </Typography>
      )}
      {madeIt && (
        <Typography variant="h2" mb={5} textAlign="center">
          {String(translate('powerOfAttorney.mainHeaders.madeIt'))}
        </Typography>
      )}
      <Stack sx={{ width: '100%' }} spacing={4}>
        {basicInfoMissing && activeStep === 0 && <RequiredBasicInfo />}
        {!basicInfoMissing && (
          <Box>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<QontoConnector />}
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'flex',
                  },
                }}
              >
                {translatedStepsData.map((step, i) => (
                  <Step
                    key={`step-${step.title}`}
                    onClick={() => {
                      if (i < activeStep) {
                        setActiveStep(i);
                      }
                      if (power_of_attorney?.service?.completed) {
                        setActiveStep(i);
                      }
                    }}
                    sx={{
                      cursor:
                        i < activeStep || power_of_attorney?.service?.completed
                          ? 'pointer'
                          : 'default',
                    }}
                  >
                    <StepLabel StepIconComponent={QontoStepIcon}>
                      {String(step.label)}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Card sx={{ p: 3, mt: 4 }}>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="subtitle1" mb={2}>
                    {String(translatedStepsData[activeStep].title)}
                  </Typography>
                  {activeStep !== translatedStepsData.length - 1 && (
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      sx={{ mb: 2 }}
                      onClick={handleOpenEnoughNow}
                    >
                      <Typography
                        variant="body1"
                        color="error"
                        sx={{ cursor: 'pointer' }}
                      >
                        {String(translate('global.enoughForNow'))}
                      </Typography>
                    </Button>
                  )}
                </Stack>
                <Divider
                  sx={{
                    mb: 3,
                  }}
                />
                {translatedStepsData[activeStep].content(dbFormValues)}
                <Divider
                  sx={{
                    mt: 4,
                  }}
                />
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={2}
                  justifyContent="flex-end"
                  sx={{
                    mt: 3,
                  }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleBack}
                    disabled={activeStep === 0}
                  >
                    <Typography>{String(translate('global.back'))}</Typography>
                  </Button>
                  {activeStep === translatedStepsData.length - 1 && (
                    <Button
                      variant="contained"
                      onClick={() => {
                        navigate('/dashboard/home');
                      }}
                      color="success"
                      sx={{
                        color: '#fff',
                      }}
                    >
                      <Typography>
                        {String(translate('global.done'))}
                      </Typography>
                    </Button>
                  )}

                  {activeStep !== translatedStepsData.length - 1 && (
                    <LoadingButton
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setNextSubmitted(activeStep);
                        scrollToTop();
                      }}
                      type="submit"
                      loading={isSubmitting}
                    >
                      <Typography>
                        {String(translate('global.next'))}
                      </Typography>
                    </LoadingButton>
                  )}
                  {hasNoLastWill &&
                    activeStep === translatedStepsData.length - 1 && (
                      <LoadingButton
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={() => {
                          if (activeStep === translatedStepsData.length - 1) {
                            navigate('/dashboard/testament');
                          }
                        }}
                        loading={isSubmitting}
                      >
                        <Typography>
                          {String(translate('global.continueToLastWill'))}
                        </Typography>
                      </LoadingButton>
                    )}
                </Stack>
              </Card>
            </FormProvider>
          </Box>
        )}
      </Stack>
      <EnoughForNowModal
        openEnoughNow={openEnoughNow}
        handleCloseEnoughNow={handleCloseEnoughNow}
      />
    </Container>
  );
};

export default PowerAttorneyForm;
